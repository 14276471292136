import React, { lazy } from 'react'
import locales from './locales'
import routes from './routes'
import getMenuItems from './menuItems'
import parseLanguages from 'base-shell/lib/utils/locale'
import Header from '../containers/Header/Header'
import Footer from '../containers/Footer/Footer'
import ScreenLoading from '../components/ScreenLoader/index'

const Loading = () => <ScreenLoading />

const config = {
  locale: {
    defaultLocale: parseLanguages(['en', 'de', 'ru'], 'en'),
    locales,
    persistKey: 'base-shell:locale',
    onError: (e) => {
      //Uncomment this to show react-intl missing translation warnings
      //console.warn(e)
      return
    },
  },
  /*auth: {
    persistKey: 'base-shell:auth',
    signInURL: '/signin',
  },*/
  routes,
  /*menu: {
    getMenuItems,
  },*/
  pages: {
    //LandingPage: LandingPage,
    PageNotFound: lazy(() => import('../pages/PageNotFound/PageNotFound')),
  },
  components: {
    Loading,
  },
  containers: {
    AppContainer: ({ children }) => (
      <div> {children} </div>
    ),

    LayoutContainer: ({ children }) => (
      <div className="CssLayoutContainer">{children}<Footer /></div>
    ),
  },
}

export default config
