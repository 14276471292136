const axios = require('axios');

let products = 'https://thinkcares-digital-mccormick-members-mark-cms-staging.qa.bbponlineservices.com/_/items'
let ext_products = '/productos?fields=*.*,usos_sugeridos.uso_sugerido.'

const service = (() => {

    const _service = {}

    _service.obtenerProductos = async () => {
        const result = await axios.get(products + ext_products + "*");
        return result;
    }

    _service.obtenerProductoPorURL = async (urlProducto) => {
        const result = await axios.get(products + "/productos?filter[url]=" + urlProducto + "&fields=*.*,usos_sugeridos.uso_sugerido.*");
        return result;
    }

    _service.obtenerCategorias = async () => {
        const result = await axios.get(products + "/categorias");
        return result.data;
    }

    _service.obtenerProductosPorCategorias = async (idCategoria) => {
        const result = await axios.get(products + ext_products + "*&filter[categoria][eq]=" + idCategoria);
        return result.data;
    }

    _service.obtenerProductosPor_Categoria_Videos = async (idCategoria) => {
        const result = await axios.get(products + ext_products + "*&filter[yt_video][neq]=&filter[categoria][eq]=" + idCategoria);
        return result.data;
    }

    return _service

})();

export default service
