import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import styles from './Header.module.css'
import styleMenu from './Menu.module.css'
import logo_sams from '../../assets/img_Header/logo_sams.svg'
import logo_sams_mob from '../../assets/img_Header/logo_sams_mob.svg'
import logo_members_mcCormick from '../../assets/img_Header/logos_fusion.svg'
import logo_members_mcCormick_mob from '../../assets/img_Header/logos_fusion_mob.svg'
import { ReactComponent as IconMenu } from '../../assets/img_Header/icon_menu.svg'
import iconCerrarMenu from '../../assets/img_Menu/iconCerrar.svg'
import iconCerrarMenu_bco from '../../assets/img_Menu/iconCerrar_bco.svg'
import img_tit_especias from '../../assets/img_Menu/especias.svg'
import img_tit_especias_bco from '../../assets/img_Menu/especias_bco.svg'
import img_quienesSomos_bco from '../../assets/img_Menu/quienesSomos_bco.svg'
import img_quienesSomos from '../../assets/img_Menu/quienesSomos.svg'
import service from '../../services/index'

const Header = (props) => {
    const intl = useIntl()
    const [StateDesplegarMenu, setStateDesplegarMenu] = useState(false);
    const [CategoriesData, setCategoriesData] = useState([]);

    const getData = async () => {
        let result = await service.obtenerCategorias()
        setCategoriesData(result.data);
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <div className={styles.HeaderDesk}>
                <div className={styles.Container}>
                    <div className={styles.BoxLogos}>
                        <div className={styles.Box1} style={{ opacity: StateDesplegarMenu ? '0' : '1' }}
                            onClick={() => setStateDesplegarMenu(true)}>
                            <IconMenu fill={props.color} />
                        </div>
                        <div className={styles.Box_LogoMembers}>
                            <Link to={{ pathname: `/`,
                            state: {e:true}}}><img src={logo_members_mcCormick} /></Link>
                        </div>
                    </div>
                    <div className={styles.Box_LogoSams}>
                        <a href="https://www.sams.com.mx/" target="_blank"><img src={logo_sams} /></a>
                    </div>
                </div>
                <Menu
                    isShowing={StateDesplegarMenu}
                    onClose={() => { setStateDesplegarMenu(false) }}
                    data={CategoriesData}
                />
            </div>
            <div className={styles.HeaderMobile}>
                <div className={styles.Container}>
                    <div className={styles.BoxMenu}>
                        <IconMenu fill={props.color} onClick={() => setStateDesplegarMenu(true)} />
                    </div>
                    <div className={styles.BoxLogos}>
                    <Link to={{ pathname: `/`,
                            state: {e:true}}}><img className={styles.Box_LogoMembers} src={logo_members_mcCormick_mob} /></Link>
                        <a href="https://www.sams.com.mx/" target="_blank"><img className={styles.Box_LogoSams} src={logo_sams_mob} /></a>
                    </div>
                </div>
                <Menu
                    isShowing={StateDesplegarMenu}
                    onClose={() => { setStateDesplegarMenu(false) }}
                    data={CategoriesData}
                />
            </div>
        </>
    )
}

function Menu({ isShowing, onClose, data }) {
    const MenuActive = { height: '483px', }
    const MenuActiveMob = { height: '592px', }
    const MenuInactive = { height: '0', }

    return (
        <>
            <div className={styleMenu.VD}>
                <div className={styleMenu.ContainerMenu} style={isShowing ? MenuActive : MenuInactive}>
                    <div className={styleMenu.BoxMenu}>
                        <img className={styleMenu.IconCerrarMenu} src={iconCerrarMenu} onClick={() => onClose()} />
                        <a href={"/quienes-somos"}><img className={styleMenu.IconTitQuienesSomos} src={img_quienesSomos} /></a>
                        <Link to={{ pathname: `/`,
                            state: {e:true}}}><img className={styleMenu.IconTitEspecias} src={img_tit_especias} /></Link>
                        <div className={styleMenu.BoxItems}>
                            {
                                data.map((element, index) => {
                                    return (
                                        <a href={"/" + element.nombre.replace(/\s/g, '-').toLowerCase().replace(",", "")}>
                                            <img src={element.icono} /><label>{element.nombre}</label>
                                        </a>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={styleMenu.VM}>
                <div className={styleMenu.ContainerMenu} style={isShowing ? MenuActiveMob : MenuInactive}>
                    <div className={styleMenu.BoxMenu}>
                        <div style={{ textAlign: "right" }}>
                            <img className={styleMenu.IconCerrarMenu} src={iconCerrarMenu_bco} onClick={() => onClose()} />
                        </div>
                        <a href={"/quienes-somos"}><img className={styleMenu.IconTitQuienesSomos} src={img_quienesSomos_bco} /></a><br />
                        <Link to={{ pathname: `/`,
                            state: {e:true}}}><img className={styleMenu.IconTitEspecias} src={img_tit_especias_bco} /></Link>
                        <div className={styleMenu.BoxItems}>
                            {
                                data.map((element, index) => {
                                    return (
                                        <a href={"/" + element.nombre.replace(/\s/g, '-').toLowerCase().replace(",", "")}>
                                            <img src={element.icono} /><label>{element.nombre}</label>
                                        </a>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouter(Header);