import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import styles from './Footer.module.css'
import TermCond from '../../components/TerminosCond/TerminosCond';

const Footer = () => {
    const intl = useIntl()
    const [TermOpen, setTermOpen] = useState(false);
    const handleClickTermOpen = () => () => {
        setTermOpen(true);
    };
  
    const handleTermClose = () => {
        setTermOpen(false);
    };
    return (
        <>
            <div className={styles.FooterDesk}>
                <div className={styles.Container}>
                    <div className={styles.Box1}>
                        {/*<a>Aviso de privacidad</a>*/}
                        <label>Todos los derechos reservados</label>
                        <a onClick={handleClickTermOpen()}>Términos y Condiciones</a>
                    </div>
                </div>
            </div>
            <div className={styles.FooterMobile}>
                <div className={styles.Container}>
                    <div className={styles.Box1}>
                        {/*<a>Aviso de privacidad</a>*/}
                        <a onClick={handleClickTermOpen()}>Términos y Condiciones</a>
                    </div>
                    <div className={styles.Box2}>
                        <label>Todos los derechos reservados</label>
                    </div>
                </div>
            </div>
            <TermCond open={TermOpen} handleClose={handleTermClose} />
        </>
    )
}

export default Footer