import React, { Component } from 'react'
import App from 'base-shell/lib'
import config from './config'
import '../node_modules/react-modal-video/scss/modal-video.scss';

export default class Demo extends Component {
  render() {
    return <App config={config} />
  }
}
