import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function TermCond(props) {
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
      if (props.open) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      }
    }, [props.open]);
  
    return (
      <div>
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          scroll='paper'
          fullWidth='true'
          maxWidth={'md'}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">TÉRMINOS Y CONDICIONES LEGALES</DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
                <p>
                    Está usted accediendo al Sitio web de McCormick de México, S.A. de C.V., y NUEVA WAL-MART DE 
                    MÉXICO, S. DE R.L. DE C.V., (en lo sucesivo “Member's Mark by McCormick”).</p>
                <p>
                    Por favor lea cuidadosamente los Términos y Condiciones Legales que regulan el acceso y uso 
                    por parte de los usuarios (el “Usuario” o “Usuarios”) del sitio web de McCORMICK DE MÉXICO, 
                    S.A. DE C.V., y NUEVA WAL-MART DE MÉXICO, S. DE R.L. DE C.V., (en lo sucesivo “Member's Mark 
                    by McCormick”), cuyo dominio es <a href="https://www.members-mark-by-mccormick.com/">https://www.members-mark-by-mccormick.com/</a> y/o afiliadas y/o subsidiarias y/o cualquier página y/o aplicación de las marcas pertenecientes a Wal-mart® y  McCormick®  (en adelante el “Sitio”), ya que el acceso y uso del Sitio atribuye la condición de Usuario del mismo y constituye el entendimiento y la aceptación de los Términos y Condiciones Legales aquí establecidos.</p>
                <p>
                    Este Sitio puede ser visitado por cualquier Usuario de forma libre y gratuita sujeto a los 
                    presentes Términos y Condiciones Legales.</p>
                <p>
                    El contenido de este Sitio tiene como finalidad dar a conocer al Usuario, información general 
                    sobre los productos y la marca Member's Mark by McCormick®, lo cual no implica ni garantiza que 
                    la Información aquí contenida pueda satisfacer los requisitos de precisión, detalle o exactitud 
                    requerida a juicio del Usuario. En tal virtud, el contenido es de índole informativo, por lo  
                    que la interpretación es personal de cada Usuario.
                </p>
                <p>
                    McCORMICK DE MÉXICO, S.A. DE C.V., y NUEVA WAL-MART DE MÉXICO, S. DE R.L. DE C.V., no asume 
                    responsabilidad alguna, derivada de que la Información disponible en este Sitio no sea precisa 
                    o completa y se reserva el derecho de modificar el contenido del Sitio en cualquier momento y 
                    sin previo aviso al Usuario, incluyendo los presentes Términos y Condiciones Legales. 
                    Las modificaciones que se hagan producirán efecto en forma inmediata a su incorporación en 
                    el Sitio.
                </p>
                <p>
                    La información de este Sitio es con relación a los productos e información exclusivamente 
                    comercializados en los Estados Unidos Mexicanos y Member's Mark by McCormick® no garantiza que 
                    la misma cumpla con la legislación de otros países, debiendo considerarse solamente como un 
                    Sitio de visita para Usuarios de cualquier otra parte del mundo, por lo que, el Usuario es 
                    el único responsable de cumplir con las leyes locales que le apliquen si ingresa al Sitio 
                    desde cualquier lugar fuera de la República Mexicana.
                </p>
                <p>
                    Al accesar a este Sitio, el Usuario acepta y reconoce que Member's Mark by McCormick® no es 
                    responsable en ningún momento por la incomunicación, interrupción, falla o inhabilitación del 
                    Sitio por causas no imputables a Member's Mark by McCormick®; así como que no será tampoco 
                    responsable de cualquier daño o perjuicio, de manera enunciativa pero no limitativa, por 
                    fallas técnicas de cualquier tipo, incluyendo daños en el equipo personal de cómputo, 
                    teléfono móvil, Tablet y/o cualquier dispositivo utilizado por los Usuarios para ingresar 
                    al Sitio o por experimentar o apreciar los materiales relacionados con el mismo, por 
                    intervención humana no autorizada, por retrasos, distorsiones o interferencias en las 
                    transmisiones telefónicas o de computadora u otros errores de cualquier tipo, ya sea 
                    humanos, electrónicos o mecánicos. En el mismo sentido, Member's Mark by McCormick® 
                    no tendrá responsabilidad alguna por cualquier pérdida causada por virus que puedan 
                    infectar a su computadora u otra propiedad por razón de usar, ingresar o bajar cualquier 
                    material de este Sitio. Si el Usuario elige descargar materiales del Sitio siempre será 
                    bajo su propio riesgo y cualquier mal uso o uso incorrecto de la información será 
                    exclusivamente su responsabilidad.
                </p>
                <p>
                    Toda la información como pueden ser, de forma enunciativa mas no limitativa: Imágenes, Videos, 
                    Textos, Marcas Registradas, Productos, Avisos Comerciales, Nombres de Dominio y Diseños, 
                    Reportes, Políticas, Comunicados de Prensa, Historia de Member's Mark by McCormick® y demás 
                    análogos (referidos en el presente documento como “Información”) contenidas en este Sitio, 
                    pueden ser propiedad de Member's Mark by McCormick®, sus titulares y/o licenciatarios, 
                    estando por ende protegida por los convenios internacionales y la legislación mexicana en 
                    materia de propiedad industrial e intelectual.
                </p>
                <p>
                    Ningún contenido de este Sitio debe interpretarse como un otorgamiento de licencia de uso o derecho 
                    de uso de la Información mostrada, por lo que se prohíbe cualquier uso, reproducción total o parcial 
                    de la Información o cualquier contenido del mismo.
                </p>
                <p>
                    El Sitio puede o no contener una sección dedicada a un “blog” o foro, en donde se podrán exponer 
                    diversos asuntos de interés en relación a actividad de Member's Mark by McCormick®, por lo que 
                    Member's Mark by McCormick® únicamente actuará de moderador dentro del “blog” o foro y las opiniones, 
                    comentarios y/o escritos de los Usuarios, reflejarán únicamente sus opiniones personales y/o criterios, 
                    por lo que Member's Mark by McCormick® no es responsable de los mismos. No obstante, Member's Mark by 
                    McCormick® se reserva el derecho de borrar comentarios ofensivos, vulgares, racistas o de cualquier 
                    calidad que pueda ofender a otras personas, a Member's Mark by McCormick® y/o a sus marcas.
                </p>
                <p>
                    El usuario acepta tácita y expresamente al momento de registrarse, que las recetas que suba al 
                    sitio y/o proporcione a Member's Mark by McCormick®, son de su autoría y cuentan con todos los 
                    derechos para poder utilizarla, liberando a Member's Mark by McCormick® de cualquier 
                    controversia y obligándose a que, en caso de presentarse cualquier controversia iniciada por 
                    cualquier tercero o autoridad, sacará en paz y a salvo a Member's Mark by McCormick®, resarciendo 
                    en su caso, sin limitarlos gastos que ésta última erogue con motivo de su defensa. En este tenor, 
                    el usuario al momento mismo de subir, transmitir y/o registrar una o varias recetas, está cediendo 
                    totalmente a Member's Mark by McCormick® los derechos para su utilización y/o publicación por 
                    cualquier medio de comunicación impresa, visual, auditiva, electrónica y/o por cualquier tipo de 
                    tecnología que se encuentre dentro de mercado en un futuro.
                </p>
                <p>
                    El Usuario se obliga a usar el Sitio, sus contenidos y/o servicios de forma diligente y de 
                    conformidad con los presentes Términos y Condiciones Legales; así como con la ley, la moral, el orden 
                    público y las buenas costumbres.
                </p>
                <p>
                    Member's Mark by McCormick® se reserva el absoluto derecho de denegar o retirar el acceso al Sitio 
                    y/o a los servicios aquí contenidos, en cualquier momento y sin necesidad de previo aviso, al 
                    Usuario que no cumpla con lo establecido en los presentes Términos y Condiciones Legales.
                </p>
                <p>
                    Asimismo, Member's Mark by McCormick® se reserva el derecho de restringir, finalizar o 
                    terminar el ingreso a este Sitio, al Usuario que intente dañar o alterar deliberadamente 
                    el Sitio o cualquiera de sus secciones, estropear, modificar o impedir la legítima operación 
                    del mismo, por lo que constituye una violación a las Leyes Federales y Locales, reservándose 
                    Member's Mark by McCormick® el derecho de actuar por la vía Civil y/o Penal y/o cualquier otra, 
                    con la finalidad de reclamar la reparación del daño y la correspondiente indemnización por Daños 
                    y Perjuicios.
                </p>
                        
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }