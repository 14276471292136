/* eslint-disable react/jsx-key */
import React, { lazy } from 'react'
import AuthorizedRoute from 'base-shell/lib/components/AuthorizedRoute/AuthorizedRoute'
import UnauthorizedRoute from 'base-shell/lib/components/UnauthorizedRoute/UnauthorizedRoute'

import { Route } from 'react-router-dom'

//const SignIn = lazy(() => import('../pages/SignIn/SignIn'))
const Home = lazy(() => import('../pages/Home/Home'))
const HomeA = lazy(() => import('../pages/HomeAnimation/Home'))
const Product = lazy(() => import('../pages/Product/Product'))
const Categories = lazy(() => import('../pages/Categories/Categories'))
const QuienesSomos = lazy(() => import('../pages/QuienesSomos/QuienesSomos') )

const routes = [
  <Route path="/" exact component={Home} />,
  <Route path="/quienes-somos" exact component={QuienesSomos} />,
  <Route path="/:categoria/:urlProducto" exact component={Product} />,
  <Route path="/:categoria" exact component={Categories} />, 
  
  
]

export default routes
