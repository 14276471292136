import React, { useContext } from 'react'
import styles from './index.module.css'

const ScreenLoading = () => {
    return (
      <div className={styles.ContenedorLoading}>
        <svg className={styles.spinner} viewBox="0 0 50 50">
            <circle className={styles.path} cx="25" cy="25" r="20" fill="none" stroke-width="10"/>
        </svg>
      </div>
          
    )
}

export default ScreenLoading